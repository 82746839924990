import React from 'react';
import './flags.css';
import FSA from '../../assets/images/saint_2.png';


const Flags = () => {
  return (
    <div className="changeDropdown">
      <div className="dropdown dropbtn ">
        <h4 className='text-white fs-6 mb-0'>FSACA</h4>
        {/* <button className="dropbtn fs-6" style={{ color: 'white', backgroundColor: 'blue' }}>
          FSA_CA
        </button> */}
      </div>
    </div>
  );
};

export default Flags;

